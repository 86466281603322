exports.data = [
  { mime_type: 'application/activemessage' },
  { mime_type: 'application/andrew-inset', ext: 'ez' },
  { mime_type: 'application/applefile' },
  { mime_type: 'application/applixware', ext: 'aw' },
  { mime_type: 'application/atom+xml', ext: 'atom' },
  { mime_type: 'application/atomcat+xml', ext: 'atomcat' },
  { mime_type: 'application/atomicmail' },
  { mime_type: 'application/atomsvc+xml', ext: 'atomsvc' },
  { mime_type: 'application/auth-policy+xml' },
  { mime_type: 'application/batch-smtp' },
  { mime_type: 'application/beep+xml' },
  { mime_type: 'application/cals-1840' },
  { mime_type: 'application/ccxml+xml', ext: 'ccxml' },
  { mime_type: 'application/cea-2018+xml' },
  { mime_type: 'application/cellml+xml' },
  { mime_type: 'application/cnrp+xml' },
  { mime_type: 'application/commonground' },
  { mime_type: 'application/conference-info+xml' },
  { mime_type: 'application/cpl+xml' },
  { mime_type: 'application/csta+xml' },
  { mime_type: 'application/cstadata+xml' },
  { mime_type: 'application/cu-seeme', ext: 'cu' },
  { mime_type: 'application/cybercash' },
  { mime_type: 'application/davmount+xml', ext: 'davmount' },
  { mime_type: 'application/dca-rft' },
  { mime_type: 'application/dec-dx' },
  { mime_type: 'application/dialog-info+xml' },
  { mime_type: 'application/dicom' },
  { mime_type: 'application/dns' },
  { mime_type: 'application/dvcs' },
  { mime_type: 'application/ecmascript', ext: 'ecma' },
  { mime_type: 'application/edi-consent' },
  { mime_type: 'application/edi-x12' },
  { mime_type: 'application/edifact' },
  { mime_type: 'application/emma+xml', ext: 'emma' },
  { mime_type: 'application/epp+xml' },
  { mime_type: 'application/epub+zip', ext: 'epub' },
  { mime_type: 'application/eshop' },
  { mime_type: 'application/example' },
  { mime_type: 'application/fastinfoset' },
  { mime_type: 'application/fastsoap' },
  { mime_type: 'application/fits' },
  { mime_type: 'application/font-tdpfr', ext: 'pfr' },
  { mime_type: 'application/h224' },
  { mime_type: 'application/http' },
  { mime_type: 'application/hyperstudio', ext: 'stk' },
  { mime_type: 'application/ibe-key-request+xml' },
  { mime_type: 'application/ibe-pkg-reply+xml' },
  { mime_type: 'application/ibe-pp-data' },
  { mime_type: 'application/iges' },
  { mime_type: 'application/im-iscomposing+xml' },
  { mime_type: 'application/index' },
  { mime_type: 'application/index.cmd' },
  { mime_type: 'application/index.obj' },
  { mime_type: 'application/index.response' },
  { mime_type: 'application/index.vnd' },
  { mime_type: 'application/iotp' },
  { mime_type: 'application/ipp' },
  { mime_type: 'application/isup' },
  { mime_type: 'application/java-archive', ext: 'jar' },
  { mime_type: 'application/java-serialized-object', ext: 'ser' },
  { mime_type: 'application/java-vm', ext: 'class' },
  { mime_type: 'application/javascript', ext: 'js' },
  { mime_type: 'application/json', ext: 'json' },
  { mime_type: 'application/kpml-request+xml' },
  { mime_type: 'application/kpml-response+xml' },
  { mime_type: 'application/lost+xml', ext: 'lostxml' },
  { mime_type: 'application/mac-binhex40', ext: 'hqx' },
  { mime_type: 'application/mac-compactpro', ext: 'cpt' },
  { mime_type: 'application/macwriteii' },
  { mime_type: 'application/marc', ext: 'mrc' },
  { mime_type: 'application/mathematica', ext: 'ma nb mb' },
  { mime_type: 'application/mathml+xml', ext: 'mathml' },
  { mime_type: 'application/mbms-associated-procedure-description+xml' },
  { mime_type: 'application/mbms-deregister+xml' },
  { mime_type: 'application/mbms-envelope+xml' },
  { mime_type: 'application/mbms-msk+xml' },
  { mime_type: 'application/mbms-msk-response+xml' },
  { mime_type: 'application/mbms-protection-description+xml' },
  { mime_type: 'application/mbms-reception-report+xml' },
  { mime_type: 'application/mbms-register+xml' },
  { mime_type: 'application/mbms-register-response+xml' },
  { mime_type: 'application/mbms-user-service-description+xml' },
  { mime_type: 'application/mbox', ext: 'mbox' },
  { mime_type: 'application/media_control+xml' },
  { mime_type: 'application/mediaservercontrol+xml', ext: 'mscml' },
  { mime_type: 'application/mikey' },
  { mime_type: 'application/moss-keys' },
  { mime_type: 'application/moss-signature' },
  { mime_type: 'application/mosskey-data' },
  { mime_type: 'application/mosskey-request' },
  { mime_type: 'application/mp4', ext: 'mp4s' },
  { mime_type: 'application/mpeg4-generic' },
  { mime_type: 'application/mpeg4-iod' },
  { mime_type: 'application/mpeg4-iod-xmt' },
  { mime_type: 'application/msword', ext: 'doc dot' },
  { mime_type: 'application/mxf', ext: 'mxf' },
  { mime_type: 'application/nasdata' },
  { mime_type: 'application/news-checkgroups' },
  { mime_type: 'application/news-groupinfo' },
  { mime_type: 'application/news-transmission' },
  { mime_type: 'application/nss' },
  { mime_type: 'application/ocsp-request' },
  { mime_type: 'application/ocsp-response' },
  { mime_type: 'application/octet-stream bin dms lha lrf lzh so iso dmg dist distz pkg bpk dump elc deploy scpt dmgpart' },
  { mime_type: 'application/oda', ext: 'oda' },
  { mime_type: 'application/oebps-package+xml', ext: 'opf' },
  { mime_type: 'application/ogg', ext: 'ogx' },
  { mime_type: 'application/onenote', ext: 'onetoc onetoc2 onetmp onepkg' },
  { mime_type: 'application/parityfec' },
  { mime_type: 'application/patch-ops-error+xml', ext: 'xer' },
  { mime_type: 'application/pdf', ext: 'pdf' },
  { mime_type: 'application/pgp-encrypted', ext: 'pgp' },
  { mime_type: 'application/pgp-keys' },
  { mime_type: 'application/pgp-signature', ext: 'asc sig' },
  { mime_type: 'application/pics-rules', ext: 'prf' },
  { mime_type: 'application/pidf+xml' },
  { mime_type: 'application/pidf-diff+xml' },
  { mime_type: 'application/pkcs10', ext: 'p10' },
  { mime_type: 'application/pkcs7-mime', ext: 'p7m p7c' },
  { mime_type: 'application/pkcs7-signature', ext: 'p7s' },
  { mime_type: 'application/pkix-cert', ext: 'cer' },
  { mime_type: 'application/pkix-crl', ext: 'crl' },
  { mime_type: 'application/pkix-pkipath', ext: 'pkipath' },
  { mime_type: 'application/pkixcmp', ext: 'pki' },
  { mime_type: 'application/pls+xml', ext: 'pls' },
  { mime_type: 'application/poc-settings+xml' },
  { mime_type: 'application/postscript', ext: 'ai eps ps' },
  { mime_type: 'application/prs.alvestrand.titrax-sheet' },
  { mime_type: 'application/prs.cww', ext: 'cww' },
  { mime_type: 'application/prs.nprend' },
  { mime_type: 'application/prs.plucker' },
  { mime_type: 'application/qsig' },
  { mime_type: 'application/rdf+xml', ext: 'rdf' },
  { mime_type: 'application/reginfo+xml', ext: 'rif' },
  { mime_type: 'application/relax-ng-compact-syntax', ext: 'rnc' },
  { mime_type: 'application/remote-printing' },
  { mime_type: 'application/resource-lists+xml', ext: 'rl' },
  { mime_type: 'application/resource-lists-diff+xml', ext: 'rld' },
  { mime_type: 'application/riscos' },
  { mime_type: 'application/rlmi+xml' },
  { mime_type: 'application/rls-services+xml', ext: 'rs' },
  { mime_type: 'application/rsd+xml', ext: 'rsd' },
  { mime_type: 'application/rss+xml', ext: 'rss' },
  { mime_type: 'application/rtf', ext: 'rtf' },
  { mime_type: 'application/rtx' },
  { mime_type: 'application/samlassertion+xml' },
  { mime_type: 'application/samlmetadata+xml' },
  { mime_type: 'application/sbml+xml', ext: 'sbml' },
  { mime_type: 'application/scvp-cv-request', ext: 'scq' },
  { mime_type: 'application/scvp-cv-response', ext: 'scs' },
  { mime_type: 'application/scvp-vp-request', ext: 'spq' },
  { mime_type: 'application/scvp-vp-response', ext: 'spp' },
  { mime_type: 'application/sdp', ext: 'sdp' },
  { mime_type: 'application/set-payment' },
  { mime_type: 'application/set-payment-initiation', ext: 'setpay' },
  { mime_type: 'application/set-registration' },
  { mime_type: 'application/set-registration-initiation', ext: 'setreg' },
  { mime_type: 'application/sgml' },
  { mime_type: 'application/sgml-open-catalog' },
  { mime_type: 'application/shf+xml', ext: 'shf' },
  { mime_type: 'application/sieve' },
  { mime_type: 'application/simple-filter+xml' },
  { mime_type: 'application/simple-message-summary' },
  { mime_type: 'application/simplesymbolcontainer' },
  { mime_type: 'application/slate' },
  { mime_type: 'application/smil' },
  { mime_type: 'application/smil+xml', ext: 'smi smil' },
  { mime_type: 'application/soap+fastinfoset' },
  { mime_type: 'application/soap+xml' },
  { mime_type: 'application/sparql-query', ext: 'rq' },
  { mime_type: 'application/sparql-results+xml', ext: 'srx' },
  { mime_type: 'application/spirits-event+xml' },
  { mime_type: 'application/srgs', ext: 'gram' },
  { mime_type: 'application/srgs+xml', ext: 'grxml' },
  { mime_type: 'application/ssml+xml', ext: 'ssml' },
  { mime_type: 'application/timestamp-query' },
  { mime_type: 'application/timestamp-reply' },
  { mime_type: 'application/tve-trigger' },
  { mime_type: 'application/ulpfec' },
  { mime_type: 'application/vemmi' },
  { mime_type: 'application/vividence.scriptfile' },
  { mime_type: 'application/vnd.3gpp.bsf+xml' },
  { mime_type: 'application/vnd.3gpp.pic-bw-large', ext: 'plb' },
  { mime_type: 'application/vnd.3gpp.pic-bw-small', ext: 'psb' },
  { mime_type: 'application/vnd.3gpp.pic-bw-var', ext: 'pvb' },
  { mime_type: 'application/vnd.3gpp.sms' },
  { mime_type: 'application/vnd.3gpp2.bcmcsinfo+xml' },
  { mime_type: 'application/vnd.3gpp2.sms' },
  { mime_type: 'application/vnd.3gpp2.tcap', ext: 'tcap' },
  { mime_type: 'application/vnd.3m.post-it-notes', ext: 'pwn' },
  { mime_type: 'application/vnd.accpac.simply.aso', ext: 'aso' },
  { mime_type: 'application/vnd.accpac.simply.imp', ext: 'imp' },
  { mime_type: 'application/vnd.acucobol', ext: 'acu' },
  { mime_type: 'application/vnd.acucorp', ext: 'atc acutc' },
  { mime_type: 'application/vnd.adobe.air-application-installer-package+zip', ext: 'air' },
  { mime_type: 'application/vnd.adobe.xdp+xml', ext: 'xdp' },
  { mime_type: 'application/vnd.adobe.xfdf', ext: 'xfdf' },
  { mime_type: 'application/vnd.aether.imp' },
  { mime_type: 'application/vnd.airzip.filesecure.azf', ext: 'azf' },
  { mime_type: 'application/vnd.airzip.filesecure.azs', ext: 'azs' },
  { mime_type: 'application/vnd.amazon.ebook', ext: 'azw' },
  { mime_type: 'application/vnd.americandynamics.acc', ext: 'acc' },
  { mime_type: 'application/vnd.amiga.ami', ext: 'ami' },
  { mime_type: 'application/vnd.android.package-archive', ext: 'apk' },
  { mime_type: 'application/vnd.anser-web-certificate-issue-initiation', ext: 'cii' },
  { mime_type: 'application/vnd.anser-web-funds-transfer-initiation', ext: 'fti' },
  { mime_type: 'application/vnd.antix.game-component', ext: 'atx' },
  { mime_type: 'application/vnd.apple.installer+xml', ext: 'mpkg' },
  { mime_type: 'application/vnd.arastra.swi', ext: 'swi' },
  { mime_type: 'application/vnd.audiograph', ext: 'aep' },
  { mime_type: 'application/vnd.autopackage' },
  { mime_type: 'application/vnd.avistar+xml' },
  { mime_type: 'application/vnd.blueice.multipass', ext: 'mpm' },
  { mime_type: 'application/vnd.bluetooth.ep.oob' },
  { mime_type: 'application/vnd.bmi', ext: 'bmi' },
  { mime_type: 'application/vnd.businessobjects', ext: 'rep' },
  { mime_type: 'application/vnd.cab-jscript' },
  { mime_type: 'application/vnd.canon-cpdl' },
  { mime_type: 'application/vnd.canon-lips' },
  { mime_type: 'application/vnd.cendio.thinlinc.clientconf' },
  { mime_type: 'application/vnd.chemdraw+xml', ext: 'cdxml' },
  { mime_type: 'application/vnd.chipnuts.karaoke-mmd', ext: 'mmd' },
  { mime_type: 'application/vnd.cinderella', ext: 'cdy' },
  { mime_type: 'application/vnd.cirpack.isdn-ext' },
  { mime_type: 'application/vnd.claymore', ext: 'cla' },
  { mime_type: 'application/vnd.clonk.c4group', ext: 'c4g c4d c4f c4p c4u' },
  { mime_type: 'application/vnd.commerce-battelle' },
  { mime_type: 'application/vnd.commonspace', ext: 'csp' },
  { mime_type: 'application/vnd.contact.cmsg', ext: 'cdbcmsg' },
  { mime_type: 'application/vnd.cosmocaller', ext: 'cmc' },
  { mime_type: 'application/vnd.crick.clicker', ext: 'clkx' },
  { mime_type: 'application/vnd.crick.clicker.keyboard', ext: 'clkk' },
  { mime_type: 'application/vnd.crick.clicker.palette', ext: 'clkp' },
  { mime_type: 'application/vnd.crick.clicker.template', ext: 'clkt' },
  { mime_type: 'application/vnd.crick.clicker.wordbank', ext: 'clkw' },
  { mime_type: 'application/vnd.criticaltools.wbs+xml', ext: 'wbs' },
  { mime_type: 'application/vnd.ctc-posml', ext: 'pml' },
  { mime_type: 'application/vnd.ctct.ws+xml' },
  { mime_type: 'application/vnd.cups-pdf' },
  { mime_type: 'application/vnd.cups-postscript' },
  { mime_type: 'application/vnd.cups-ppd', ext: 'ppd' },
  { mime_type: 'application/vnd.cups-raster' },
  { mime_type: 'application/vnd.cups-raw' },
  { mime_type: 'application/vnd.curl.car', ext: 'car' },
  { mime_type: 'application/vnd.curl.pcurl', ext: 'pcurl' },
  { mime_type: 'application/vnd.cybank' },
  { mime_type: 'application/vnd.data-vision.rdz', ext: 'rdz' },
  { mime_type: 'application/vnd.denovo.fcselayout-link', ext: 'fe_launch' },
  { mime_type: 'application/vnd.dir-bi.plate-dl-nosuffix' },
  { mime_type: 'application/vnd.dna', ext: 'dna' },
  { mime_type: 'application/vnd.dolby.mlp', ext: 'mlp' },
  { mime_type: 'application/vnd.dolby.mobile.1' },
  { mime_type: 'application/vnd.dolby.mobile.2' },
  { mime_type: 'application/vnd.dpgraph', ext: 'dpg' },
  { mime_type: 'application/vnd.dreamfactory', ext: 'dfac' },
  { mime_type: 'application/vnd.dvb.esgcontainer' },
  { mime_type: 'application/vnd.dvb.ipdcdftnotifaccess' },
  { mime_type: 'application/vnd.dvb.ipdcesgaccess' },
  { mime_type: 'application/vnd.dvb.ipdcroaming' },
  { mime_type: 'application/vnd.dvb.iptv.alfec-base' },
  { mime_type: 'application/vnd.dvb.iptv.alfec-enhancement' },
  { mime_type: 'application/vnd.dvb.notif-aggregate-root+xml' },
  { mime_type: 'application/vnd.dvb.notif-container+xml' },
  { mime_type: 'application/vnd.dvb.notif-generic+xml' },
  { mime_type: 'application/vnd.dvb.notif-ia-msglist+xml' },
  { mime_type: 'application/vnd.dvb.notif-ia-registration-request+xml' },
  { mime_type: 'application/vnd.dvb.notif-ia-registration-response+xml' },
  { mime_type: 'application/vnd.dvb.notif-init+xml' },
  { mime_type: 'application/vnd.dxr' },
  { mime_type: 'application/vnd.dynageo', ext: 'geo' },
  { mime_type: 'application/vnd.ecdis-update' },
  { mime_type: 'application/vnd.ecowin.chart', ext: 'mag' },
  { mime_type: 'application/vnd.ecowin.filerequest' },
  { mime_type: 'application/vnd.ecowin.fileupdate' },
  { mime_type: 'application/vnd.ecowin.series' },
  { mime_type: 'application/vnd.ecowin.seriesrequest' },
  { mime_type: 'application/vnd.ecowin.seriesupdate' },
  { mime_type: 'application/vnd.emclient.accessrequest+xml' },
  { mime_type: 'application/vnd.enliven', ext: 'nml' },
  { mime_type: 'application/vnd.epson.esf', ext: 'esf' },
  { mime_type: 'application/vnd.epson.msf', ext: 'msf' },
  { mime_type: 'application/vnd.epson.quickanime', ext: 'qam' },
  { mime_type: 'application/vnd.epson.salt', ext: 'slt' },
  { mime_type: 'application/vnd.epson.ssf', ext: 'ssf' },
  { mime_type: 'application/vnd.ericsson.quickcall' },
  { mime_type: 'application/vnd.eszigno3+xml', ext: 'es3 et3' },
  { mime_type: 'application/vnd.etsi.aoc+xml' },
  { mime_type: 'application/vnd.etsi.cug+xml' },
  { mime_type: 'application/vnd.etsi.iptvcommand+xml' },
  { mime_type: 'application/vnd.etsi.iptvdiscovery+xml' },
  { mime_type: 'application/vnd.etsi.iptvprofile+xml' },
  { mime_type: 'application/vnd.etsi.iptvsad-bc+xml' },
  { mime_type: 'application/vnd.etsi.iptvsad-cod+xml' },
  { mime_type: 'application/vnd.etsi.iptvsad-npvr+xml' },
  { mime_type: 'application/vnd.etsi.iptvueprofile+xml' },
  { mime_type: 'application/vnd.etsi.mcid+xml' },
  { mime_type: 'application/vnd.etsi.sci+xml' },
  { mime_type: 'application/vnd.etsi.simservs+xml' },
  { mime_type: 'application/vnd.eudora.data' },
  { mime_type: 'application/vnd.ezpix-album', ext: 'ez2' },
  { mime_type: 'application/vnd.ezpix-package', ext: 'ez3' },
  { mime_type: 'application/vnd.f-secure.mobile' },
  { mime_type: 'application/vnd.fdf', ext: 'fdf' },
  { mime_type: 'application/vnd.fdsn.mseed', ext: 'mseed' },
  { mime_type: 'application/vnd.fdsn.seed', ext: 'seed dataless' },
  { mime_type: 'application/vnd.ffsns' },
  { mime_type: 'application/vnd.fints' },
  { mime_type: 'application/vnd.flographit', ext: 'gph' },
  { mime_type: 'application/vnd.fluxtime.clip', ext: 'ftc' },
  { mime_type: 'application/vnd.font-fontforge-sfd' },
  { mime_type: 'application/vnd.framemaker', ext: 'fm frame maker book' },
  { mime_type: 'application/vnd.frogans.fnc', ext: 'fnc' },
  { mime_type: 'application/vnd.frogans.ltf', ext: 'ltf' },
  { mime_type: 'application/vnd.fsc.weblaunch', ext: 'fsc' },
  { mime_type: 'application/vnd.fujitsu.oasys', ext: 'oas' },
  { mime_type: 'application/vnd.fujitsu.oasys2', ext: 'oa2' },
  { mime_type: 'application/vnd.fujitsu.oasys3', ext: 'oa3' },
  { mime_type: 'application/vnd.fujitsu.oasysgp', ext: 'fg5' },
  { mime_type: 'application/vnd.fujitsu.oasysprs', ext: 'bh2' },
  { mime_type: 'application/vnd.fujixerox.art-ex' },
  { mime_type: 'application/vnd.fujixerox.art4' },
  { mime_type: 'application/vnd.fujixerox.hbpl' },
  { mime_type: 'application/vnd.fujixerox.ddd', ext: 'ddd' },
  { mime_type: 'application/vnd.fujixerox.docuworks', ext: 'xdw' },
  { mime_type: 'application/vnd.fujixerox.docuworks.binder', ext: 'xbd' },
  { mime_type: 'application/vnd.fut-misnet' },
  { mime_type: 'application/vnd.fuzzysheet', ext: 'fzs' },
  { mime_type: 'application/vnd.genomatix.tuxedo', ext: 'txd' },
  { mime_type: 'application/vnd.geogebra.file', ext: 'ggb' },
  { mime_type: 'application/vnd.geogebra.tool', ext: 'ggt' },
  { mime_type: 'application/vnd.geometry-explorer', ext: 'gex gre' },
  { mime_type: 'application/vnd.gmx', ext: 'gmx' },
  { mime_type: 'application/vnd.google-earth.kml+xml', ext: 'kml' },
  { mime_type: 'application/vnd.google-earth.kmz', ext: 'kmz' },
  { mime_type: 'application/vnd.grafeq', ext: 'gqf gqs' },
  { mime_type: 'application/vnd.gridmp' },
  { mime_type: 'application/vnd.groove-account', ext: 'gac' },
  { mime_type: 'application/vnd.groove-help', ext: 'ghf' },
  { mime_type: 'application/vnd.groove-identity-message', ext: 'gim' },
  { mime_type: 'application/vnd.groove-injector', ext: 'grv' },
  { mime_type: 'application/vnd.groove-tool-message', ext: 'gtm' },
  { mime_type: 'application/vnd.groove-tool-template', ext: 'tpl' },
  { mime_type: 'application/vnd.groove-vcard', ext: 'vcg' },
  { mime_type: 'application/vnd.handheld-entertainment+xml', ext: 'zmm' },
  { mime_type: 'application/vnd.hbci', ext: 'hbci' },
  { mime_type: 'application/vnd.hcl-bireports' },
  { mime_type: 'application/vnd.hhe.lesson-player', ext: 'les' },
  { mime_type: 'application/vnd.hp-hpgl', ext: 'hpgl' },
  { mime_type: 'application/vnd.hp-hpid', ext: 'hpid' },
  { mime_type: 'application/vnd.hp-hps', ext: 'hps' },
  { mime_type: 'application/vnd.hp-jlyt', ext: 'jlt' },
  { mime_type: 'application/vnd.hp-pcl', ext: 'pcl' },
  { mime_type: 'application/vnd.hp-pclxl', ext: 'pclxl' },
  { mime_type: 'application/vnd.httphone' },
  { mime_type: 'application/vnd.hydrostatix.sof-data', ext: 'sfd-hdstx' },
  { mime_type: 'application/vnd.hzn-3d-crossword', ext: 'x3d' },
  { mime_type: 'application/vnd.ibm.afplinedata' },
  { mime_type: 'application/vnd.ibm.electronic-media' },
  { mime_type: 'application/vnd.ibm.minipay', ext: 'mpy' },
  { mime_type: 'application/vnd.ibm.modcap', ext: 'afp listafp list3820' },
  { mime_type: 'application/vnd.ibm.rights-management', ext: 'irm' },
  { mime_type: 'application/vnd.ibm.secure-container', ext: 'sc' },
  { mime_type: 'application/vnd.iccprofile', ext: 'icc icm' },
  { mime_type: 'application/vnd.igloader', ext: 'igl' },
  { mime_type: 'application/vnd.immervision-ivp', ext: 'ivp' },
  { mime_type: 'application/vnd.immervision-ivu', ext: 'ivu' },
  { mime_type: 'application/vnd.informedcontrol.rms+xml' },
  { mime_type: 'application/vnd.informix-visionary' },
  { mime_type: 'application/vnd.intercon.formnet', ext: 'xpw xpx' },
  { mime_type: 'application/vnd.intertrust.digibox' },
  { mime_type: 'application/vnd.intertrust.nncp' },
  { mime_type: 'application/vnd.intu.qbo', ext: 'qbo' },
  { mime_type: 'application/vnd.intu.qfx', ext: 'qfx' },
  { mime_type: 'application/vnd.iptc.g2.conceptitem+xml' },
  { mime_type: 'application/vnd.iptc.g2.knowledgeitem+xml' },
  { mime_type: 'application/vnd.iptc.g2.newsitem+xml' },
  { mime_type: 'application/vnd.iptc.g2.packageitem+xml' },
  { mime_type: 'application/vnd.ipunplugged.rcprofile', ext: 'rcprofile' },
  { mime_type: 'application/vnd.irepository.package+xml', ext: 'irp' },
  { mime_type: 'application/vnd.is-xpr', ext: 'xpr' },
  { mime_type: 'application/vnd.jam', ext: 'jam' },
  { mime_type: 'application/vnd.japannet-directory-service' },
  { mime_type: 'application/vnd.japannet-jpnstore-wakeup' },
  { mime_type: 'application/vnd.japannet-payment-wakeup' },
  { mime_type: 'application/vnd.japannet-registration' },
  { mime_type: 'application/vnd.japannet-registration-wakeup' },
  { mime_type: 'application/vnd.japannet-setstore-wakeup' },
  { mime_type: 'application/vnd.japannet-verification' },
  { mime_type: 'application/vnd.japannet-verification-wakeup' },
  { mime_type: 'application/vnd.jcp.javame.midlet-rms', ext: 'rms' },
  { mime_type: 'application/vnd.jisp', ext: 'jisp' },
  { mime_type: 'application/vnd.joost.joda-archive', ext: 'joda' },
  { mime_type: 'application/vnd.kahootz', ext: 'ktz ktr' },
  { mime_type: 'application/vnd.kde.karbon', ext: 'karbon' },
  { mime_type: 'application/vnd.kde.kchart', ext: 'chrt' },
  { mime_type: 'application/vnd.kde.kformula', ext: 'kfo' },
  { mime_type: 'application/vnd.kde.kivio', ext: 'flw' },
  { mime_type: 'application/vnd.kde.kontour', ext: 'kon' },
  { mime_type: 'application/vnd.kde.kpresenter', ext: 'kpr kpt' },
  { mime_type: 'application/vnd.kde.kspread', ext: 'ksp' },
  { mime_type: 'application/vnd.kde.kword', ext: 'kwd kwt' },
  { mime_type: 'application/vnd.kenameaapp', ext: 'htke' },
  { mime_type: 'application/vnd.kidspiration', ext: 'kia' },
  { mime_type: 'application/vnd.kinar', ext: 'kne knp' },
  { mime_type: 'application/vnd.koan', ext: 'skp skd skt skm' },
  { mime_type: 'application/vnd.kodak-descriptor', ext: 'sse' },
  { mime_type: 'application/vnd.liberty-request+xml' },
  { mime_type: 'application/vnd.llamagraphics.life-balance.desktop', ext: 'lbd' },
  { mime_type: 'application/vnd.llamagraphics.life-balance.exchange+xml', ext: 'lbe' },
  { mime_type: 'application/vnd.lotus-1-2-3', ext: '123' },
  { mime_type: 'application/vnd.lotus-approach', ext: 'apr' },
  { mime_type: 'application/vnd.lotus-freelance', ext: 'pre' },
  { mime_type: 'application/vnd.lotus-notes', ext: 'nsf' },
  { mime_type: 'application/vnd.lotus-organizer', ext: 'org' },
  { mime_type: 'application/vnd.lotus-screencam', ext: 'scm' },
  { mime_type: 'application/vnd.lotus-wordpro', ext: 'lwp' },
  { mime_type: 'application/vnd.macports.portpkg', ext: 'portpkg' },
  { mime_type: 'application/vnd.marlin.drm.actiontoken+xml' },
  { mime_type: 'application/vnd.marlin.drm.conftoken+xml' },
  { mime_type: 'application/vnd.marlin.drm.license+xml' },
  { mime_type: 'application/vnd.marlin.drm.mdcf' },
  { mime_type: 'application/vnd.mcd', ext: 'mcd' },
  { mime_type: 'application/vnd.medcalcdata', ext: 'mc1' },
  { mime_type: 'application/vnd.mediastation.cdkey', ext: 'cdkey' },
  { mime_type: 'application/vnd.meridian-slingshot' },
  { mime_type: 'application/vnd.mfer', ext: 'mwf' },
  { mime_type: 'application/vnd.mfmp', ext: 'mfm' },
  { mime_type: 'application/vnd.micrografx.flo', ext: 'flo' },
  { mime_type: 'application/vnd.micrografx.igx', ext: 'igx' },
  { mime_type: 'application/vnd.mif', ext: 'mif' },
  { mime_type: 'application/vnd.minisoft-hp3000-save' },
  { mime_type: 'application/vnd.mitsubishi.misty-guard.trustweb' },
  { mime_type: 'application/vnd.mobius.daf', ext: 'daf' },
  { mime_type: 'application/vnd.mobius.dis', ext: 'dis' },
  { mime_type: 'application/vnd.mobius.mbk', ext: 'mbk' },
  { mime_type: 'application/vnd.mobius.mqy', ext: 'mqy' },
  { mime_type: 'application/vnd.mobius.msl', ext: 'msl' },
  { mime_type: 'application/vnd.mobius.plc', ext: 'plc' },
  { mime_type: 'application/vnd.mobius.txf', ext: 'txf' },
  { mime_type: 'application/vnd.mophun.application', ext: 'mpn' },
  { mime_type: 'application/vnd.mophun.certificate', ext: 'mpc' },
  { mime_type: 'application/vnd.motorola.flexsuite' },
  { mime_type: 'application/vnd.motorola.flexsuite.adsi' },
  { mime_type: 'application/vnd.motorola.flexsuite.fis' },
  { mime_type: 'application/vnd.motorola.flexsuite.gotap' },
  { mime_type: 'application/vnd.motorola.flexsuite.kmr' },
  { mime_type: 'application/vnd.motorola.flexsuite.ttc' },
  { mime_type: 'application/vnd.motorola.flexsuite.wem' },
  { mime_type: 'application/vnd.motorola.iprm' },
  { mime_type: 'application/vnd.mozilla.xul+xml', ext: 'xul' },
  { mime_type: 'application/vnd.ms-artgalry', ext: 'cil' },
  { mime_type: 'application/vnd.ms-asf' },
  { mime_type: 'application/vnd.ms-cab-compressed', ext: 'cab' },
  { mime_type: 'application/vnd.ms-excel', ext: 'xls xlm xla xlc xlt xlw' },
  { mime_type: 'application/vnd.ms-excel.addin.macroenabled.12', ext: 'xlam' },
  { mime_type: 'application/vnd.ms-excel.sheet.binary.macroenabled.12', ext: 'xlsb' },
  { mime_type: 'application/vnd.ms-excel.sheet.macroenabled.12', ext: 'xlsm' },
  { mime_type: 'application/vnd.ms-excel.template.macroenabled.12', ext: 'xltm' },
  { mime_type: 'application/vnd.ms-fontobject', ext: 'eot' },
  { mime_type: 'application/vnd.ms-htmlhelp', ext: 'chm' },
  { mime_type: 'application/vnd.ms-ims', ext: 'ims' },
  { mime_type: 'application/vnd.ms-lrm', ext: 'lrm' },
  { mime_type: 'application/vnd.ms-pki.seccat', ext: 'cat' },
  { mime_type: 'application/vnd.ms-pki.stl', ext: 'stl' },
  { mime_type: 'application/vnd.ms-playready.initiator+xml' },
  { mime_type: 'application/vnd.ms-powerpoint', ext: 'ppt pps pot' },
  { mime_type: 'application/vnd.ms-powerpoint.addin.macroenabled.12', ext: 'ppam' },
  { mime_type: 'application/vnd.ms-powerpoint.presentation.macroenabled.12', ext: 'pptm' },
  { mime_type: 'application/vnd.ms-powerpoint.slide.macroenabled.12', ext: 'sldm' },
  { mime_type: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12', ext: 'ppsm' },
  { mime_type: 'application/vnd.ms-powerpoint.template.macroenabled.12', ext: 'potm' },
  { mime_type: 'application/vnd.ms-project', ext: 'mpp mpt' },
  { mime_type: 'application/vnd.ms-tnef' },
  { mime_type: 'application/vnd.ms-wmdrm.lic-chlg-req' },
  { mime_type: 'application/vnd.ms-wmdrm.lic-resp' },
  { mime_type: 'application/vnd.ms-wmdrm.meter-chlg-req' },
  { mime_type: 'application/vnd.ms-wmdrm.meter-resp' },
  { mime_type: 'application/vnd.ms-word.document.macroenabled.12', ext: 'docm' },
  { mime_type: 'application/vnd.ms-word.template.macroenabled.12', ext: 'dotm' },
  { mime_type: 'application/vnd.ms-works', ext: 'wps wks wcm wdb' },
  { mime_type: 'application/vnd.ms-wpl', ext: 'wpl' },
  { mime_type: 'application/vnd.ms-xpsdocument', ext: 'xps' },
  { mime_type: 'application/vnd.mseq', ext: 'mseq' },
  { mime_type: 'application/vnd.msign' },
  { mime_type: 'application/vnd.multiad.creator' },
  { mime_type: 'application/vnd.multiad.creator.cif' },
  { mime_type: 'application/vnd.music-niff' },
  { mime_type: 'application/vnd.musician', ext: 'mus' },
  { mime_type: 'application/vnd.muvee.style', ext: 'msty' },
  { mime_type: 'application/vnd.ncd.control' },
  { mime_type: 'application/vnd.ncd.reference' },
  { mime_type: 'application/vnd.nervana' },
  { mime_type: 'application/vnd.netfpx' },
  { mime_type: 'application/vnd.neurolanguage.nlu', ext: 'nlu' },
  { mime_type: 'application/vnd.noblenet-directory', ext: 'nnd' },
  { mime_type: 'application/vnd.noblenet-sealer', ext: 'nns' },
  { mime_type: 'application/vnd.noblenet-web', ext: 'nnw' },
  { mime_type: 'application/vnd.nokia.catalogs' },
  { mime_type: 'application/vnd.nokia.conml+wbxml' },
  { mime_type: 'application/vnd.nokia.conml+xml' },
  { mime_type: 'application/vnd.nokia.isds-radio-presets' },
  { mime_type: 'application/vnd.nokia.iptv.config+xml' },
  { mime_type: 'application/vnd.nokia.landmark+wbxml' },
  { mime_type: 'application/vnd.nokia.landmark+xml' },
  { mime_type: 'application/vnd.nokia.landmarkcollection+xml' },
  { mime_type: 'application/vnd.nokia.n-gage.ac+xml' },
  { mime_type: 'application/vnd.nokia.n-gage.data', ext: 'ngdat' },
  { mime_type: 'application/vnd.nokia.n-gage.symbian.install', ext: 'n-gage' },
  { mime_type: 'application/vnd.nokia.ncd' },
  { mime_type: 'application/vnd.nokia.pcd+wbxml' },
  { mime_type: 'application/vnd.nokia.pcd+xml' },
  { mime_type: 'application/vnd.nokia.radio-preset', ext: 'rpst' },
  { mime_type: 'application/vnd.nokia.radio-presets', ext: 'rpss' },
  { mime_type: 'application/vnd.novadigm.edm', ext: 'edm' },
  { mime_type: 'application/vnd.novadigm.edx', ext: 'edx' },
  { mime_type: 'application/vnd.novadigm.ext', ext: 'ext' },
  { mime_type: 'application/vnd.oasis.opendocument.chart', ext: 'odc' },
  { mime_type: 'application/vnd.oasis.opendocument.chart-template', ext: 'otc' },
  { mime_type: 'application/vnd.oasis.opendocument.database', ext: 'odb' },
  { mime_type: 'application/vnd.oasis.opendocument.formula', ext: 'odf' },
  { mime_type: 'application/vnd.oasis.opendocument.formula-template', ext: 'odft' },
  { mime_type: 'application/vnd.oasis.opendocument.graphics', ext: 'odg' },
  { mime_type: 'application/vnd.oasis.opendocument.graphics-template', ext: 'otg' },
  { mime_type: 'application/vnd.oasis.opendocument.image', ext: 'odi' },
  { mime_type: 'application/vnd.oasis.opendocument.image-template', ext: 'oti' },
  { mime_type: 'application/vnd.oasis.opendocument.presentation', ext: 'odp' },
  { mime_type: 'application/vnd.oasis.opendocument.presentation-template otp' },
  { mime_type: 'application/vnd.oasis.opendocument.spreadsheet', ext: 'ods' },
  { mime_type: 'application/vnd.oasis.opendocument.spreadsheet-template', ext: 'ots' },
  { mime_type: 'application/vnd.oasis.opendocument.text', ext: 'odt' },
  { mime_type: 'application/vnd.oasis.opendocument.text-master', ext: 'otm' },
  { mime_type: 'application/vnd.oasis.opendocument.text-template', ext: 'ott' },
  { mime_type: 'application/vnd.oasis.opendocument.text-web', ext: 'oth' },
  { mime_type: 'application/vnd.obn' },
  { mime_type: 'application/vnd.olpc-sugar', ext: 'xo' },
  { mime_type: 'application/vnd.oma-scws-config' },
  { mime_type: 'application/vnd.oma-scws-http-request' },
  { mime_type: 'application/vnd.oma-scws-http-response' },
  { mime_type: 'application/vnd.oma.bcast.associated-procedure-parameter+xml' },
  { mime_type: 'application/vnd.oma.bcast.drm-trigger+xml' },
  { mime_type: 'application/vnd.oma.bcast.imd+xml' },
  { mime_type: 'application/vnd.oma.bcast.ltkm' },
  { mime_type: 'application/vnd.oma.bcast.notification+xml' },
  { mime_type: 'application/vnd.oma.bcast.provisioningtrigger' },
  { mime_type: 'application/vnd.oma.bcast.sgboot' },
  { mime_type: 'application/vnd.oma.bcast.sgdd+xml' },
  { mime_type: 'application/vnd.oma.bcast.sgdu' },
  { mime_type: 'application/vnd.oma.bcast.simple-symbol-container' },
  { mime_type: 'application/vnd.oma.bcast.smartcard-trigger+xml' },
  { mime_type: 'application/vnd.oma.bcast.sprov+xml' },
  { mime_type: 'application/vnd.oma.bcast.stkm' },
  { mime_type: 'application/vnd.oma.dcd' },
  { mime_type: 'application/vnd.oma.dcdc' },
  { mime_type: 'application/vnd.oma.dd2+xml', ext: 'dd2' },
  { mime_type: 'application/vnd.oma.drm.risd+xml' },
  { mime_type: 'application/vnd.oma.group-usage-list+xml' },
  { mime_type: 'application/vnd.oma.poc.detailed-progress-report+xml' },
  { mime_type: 'application/vnd.oma.poc.final-report+xml' },
  { mime_type: 'application/vnd.oma.poc.groups+xml' },
  { mime_type: 'application/vnd.oma.poc.invocation-descriptor+xml' },
  { mime_type: 'application/vnd.oma.poc.optimized-progress-report+xml' },
  { mime_type: 'application/vnd.oma.xcap-directory+xml' },
  { mime_type: 'application/vnd.omads-email+xml' },
  { mime_type: 'application/vnd.omads-file+xml' },
  { mime_type: 'application/vnd.omads-folder+xml' },
  { mime_type: 'application/vnd.omaloc-supl-init' },
  { mime_type: 'application/vnd.openofficeorg.extension', ext: 'oxt' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', ext: 'pptx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.slide', ext: 'sldx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', ext: 'ppsx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml.template', ext: 'potx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ext: 'xlsx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template', ext: 'xltx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', ext: 'docx' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', ext: 'dotx' },
  { mime_type: 'application/vnd.osa.netdeploy' },
  { mime_type: 'application/vnd.osgi.bundle' },
  { mime_type: 'application/vnd.osgi.dp', ext: 'dp' },
  { mime_type: 'application/vnd.otps.ct-kip+xml' },
  { mime_type: 'application/vnd.palm', ext: 'pdb pqa oprc' },
  { mime_type: 'application/vnd.paos.xml' },
  { mime_type: 'application/vnd.pg.format', ext: 'str' },
  { mime_type: 'application/vnd.pg.osasli', ext: 'ei6' },
  { mime_type: 'application/vnd.piaccess.application-licence' },
  { mime_type: 'application/vnd.picsel', ext: 'efif' },
  { mime_type: 'application/vnd.poc.group-advertisement+xml' },
  { mime_type: 'application/vnd.pocketlearn', ext: 'plf' },
  { mime_type: 'application/vnd.powerbuilder6', ext: 'pbd' },
  { mime_type: 'application/vnd.powerbuilder6-s' },
  { mime_type: 'application/vnd.powerbuilder7' },
  { mime_type: 'application/vnd.powerbuilder7-s' },
  { mime_type: 'application/vnd.powerbuilder75' },
  { mime_type: 'application/vnd.powerbuilder75-s' },
  { mime_type: 'application/vnd.preminet' },
  { mime_type: 'application/vnd.previewsystems.box', ext: 'box' },
  { mime_type: 'application/vnd.proteus.magazine', ext: 'mgz' },
  { mime_type: 'application/vnd.publishare-delta-tree', ext: 'qps' },
  { mime_type: 'application/vnd.pvi.ptid1', ext: 'ptid' },
  { mime_type: 'application/vnd.pwg-multiplexed' },
  { mime_type: 'application/vnd.pwg-xhtml-print+xml' },
  { mime_type: 'application/vnd.qualcomm.brew-app-res' },
  { mime_type: 'application/vnd.quark.quarkxpress', ext: 'qxd qxt qwd qwt qxl qxb' },
  { mime_type: 'application/vnd.rapid' },
  { mime_type: 'application/vnd.recordare.musicxml', ext: 'mxl' },
  { mime_type: 'application/vnd.recordare.musicxml+xml', ext: 'musicxml' },
  { mime_type: 'application/vnd.renlearn.rlprint' },
  { mime_type: 'application/vnd.rim.cod', ext: 'cod' },
  { mime_type: 'application/vnd.rn-realmedia', ext: 'rm' },
  { mime_type: 'application/vnd.route66.link66+xml', ext: 'link66' },
  { mime_type: 'application/vnd.ruckus.download' },
  { mime_type: 'application/vnd.s3sms' },
  { mime_type: 'application/vnd.sbm.cid' },
  { mime_type: 'application/vnd.sbm.mid2' },
  { mime_type: 'application/vnd.scribus' },
  { mime_type: 'application/vnd.sealed.3df' },
  { mime_type: 'application/vnd.sealed.csf' },
  { mime_type: 'application/vnd.sealed.doc' },
  { mime_type: 'application/vnd.sealed.eml' },
  { mime_type: 'application/vnd.sealed.mht' },
  { mime_type: 'application/vnd.sealed.net' },
  { mime_type: 'application/vnd.sealed.ppt' },
  { mime_type: 'application/vnd.sealed.tiff' },
  { mime_type: 'application/vnd.sealed.xls' },
  { mime_type: 'application/vnd.sealedmedia.softseal.html' },
  { mime_type: 'application/vnd.sealedmedia.softseal.pdf' },
  { mime_type: 'application/vnd.seemail', ext: 'see' },
  { mime_type: 'application/vnd.sema', ext: 'sema' },
  { mime_type: 'application/vnd.semd', ext: 'semd' },
  { mime_type: 'application/vnd.semf', ext: 'semf' },
  { mime_type: 'application/vnd.shana.informed.formdata', ext: 'ifm' },
  { mime_type: 'application/vnd.shana.informed.formtemplate', ext: 'itp' },
  { mime_type: 'application/vnd.shana.informed.interchange', ext: 'iif' },
  { mime_type: 'application/vnd.shana.informed.package', ext: 'ipk' },
  { mime_type: 'application/vnd.simtech-mindmapper', ext: 'twd twds' },
  { mime_type: 'application/vnd.smaf', ext: 'mmf' },
  { mime_type: 'application/vnd.smart.teacher', ext: 'teacher' },
  { mime_type: 'application/vnd.software602.filler.form+xml' },
  { mime_type: 'application/vnd.software602.filler.form-xml-zip' },
  { mime_type: 'application/vnd.solent.sdkm+xml', ext: 'sdkm sdkd' },
  { mime_type: 'application/vnd.spotfire.dxp', ext: 'dxp' },
  { mime_type: 'application/vnd.spotfire.sfs', ext: 'sfs' },
  { mime_type: 'application/vnd.sss-cod' },
  { mime_type: 'application/vnd.sss-dtf' },
  { mime_type: 'application/vnd.sss-ntf' },
  { mime_type: 'application/vnd.stardivision.calc', ext: 'sdc' },
  { mime_type: 'application/vnd.stardivision.draw', ext: 'sda' },
  { mime_type: 'application/vnd.stardivision.impress', ext: 'sdd' },
  { mime_type: 'application/vnd.stardivision.math', ext: 'smf' },
  { mime_type: 'application/vnd.stardivision.writer', ext: 'sdw' },
  { mime_type: 'application/vnd.stardivision.writer', ext: 'vor' },
  { mime_type: 'application/vnd.stardivision.writer-global', ext: 'sgl' },
  { mime_type: 'application/vnd.street-stream' },
  { mime_type: 'application/vnd.sun.xml.calc', ext: 'sxc' },
  { mime_type: 'application/vnd.sun.xml.calc.template', ext: 'stc' },
  { mime_type: 'application/vnd.sun.xml.draw', ext: 'sxd' },
  { mime_type: 'application/vnd.sun.xml.draw.template', ext: 'std' },
  { mime_type: 'application/vnd.sun.xml.impress', ext: 'sxi' },
  { mime_type: 'application/vnd.sun.xml.impress.template', ext: 'sti' },
  { mime_type: 'application/vnd.sun.xml.math', ext: 'sxm' },
  { mime_type: 'application/vnd.sun.xml.writer', ext: 'sxw' },
  { mime_type: 'application/vnd.sun.xml.writer.global', ext: 'sxg' },
  { mime_type: 'application/vnd.sun.xml.writer.template', ext: 'stw' },
  { mime_type: 'application/vnd.sun.wadl+xml' },
  { mime_type: 'application/vnd.sus-calendar', ext: 'sus susp' },
  { mime_type: 'application/vnd.svd', ext: 'svd' },
  { mime_type: 'application/vnd.swiftview-ics' },
  { mime_type: 'application/vnd.symbian.install', ext: 'sis sisx' },
  { mime_type: 'application/vnd.syncml+xml', ext: 'xsm' },
  { mime_type: 'application/vnd.syncml.dm+wbxml', ext: 'bdm' },
  { mime_type: 'application/vnd.syncml.dm+xml', ext: 'xdm' },
  { mime_type: 'application/vnd.syncml.dm.notification' },
  { mime_type: 'application/vnd.syncml.ds.notification' },
  { mime_type: 'application/vnd.tao.intent-module-archive', ext: 'tao' },
  { mime_type: 'application/vnd.tmobile-livetv', ext: 'tmo' },
  { mime_type: 'application/vnd.trid.tpt', ext: 'tpt' },
  { mime_type: 'application/vnd.triscape.mxs', ext: 'mxs' },
  { mime_type: 'application/vnd.trueapp', ext: 'tra' },
  { mime_type: 'application/vnd.truedoc' },
  { mime_type: 'application/vnd.ufdl', ext: 'ufd ufdl' },
  { mime_type: 'application/vnd.uiq.theme', ext: 'utz' },
  { mime_type: 'application/vnd.umajin', ext: 'umj' },
  { mime_type: 'application/vnd.unity', ext: 'unityweb' },
  { mime_type: 'application/vnd.uoml+xml', ext: 'uoml' },
  { mime_type: 'application/vnd.uplanet.alert' },
  { mime_type: 'application/vnd.uplanet.alert-wbxml' },
  { mime_type: 'application/vnd.uplanet.bearer-choice' },
  { mime_type: 'application/vnd.uplanet.bearer-choice-wbxml' },
  { mime_type: 'application/vnd.uplanet.cacheop' },
  { mime_type: 'application/vnd.uplanet.cacheop-wbxml' },
  { mime_type: 'application/vnd.uplanet.channel' },
  { mime_type: 'application/vnd.uplanet.channel-wbxml' },
  { mime_type: 'application/vnd.uplanet.list' },
  { mime_type: 'application/vnd.uplanet.list-wbxml' },
  { mime_type: 'application/vnd.uplanet.listcmd' },
  { mime_type: 'application/vnd.uplanet.listcmd-wbxml' },
  { mime_type: 'application/vnd.uplanet.signal' },
  { mime_type: 'application/vnd.vcx', ext: 'vcx' },
  { mime_type: 'application/vnd.vd-study' },
  { mime_type: 'application/vnd.vectorworks' },
  { mime_type: 'application/vnd.vidsoft.vidconference' },
  { mime_type: 'application/vnd.visio', ext: 'vsd vst vss vsw' },
  { mime_type: 'application/vnd.visionary', ext: 'vis' },
  { mime_type: 'application/vnd.vividence.scriptfile' },
  { mime_type: 'application/vnd.vsf', ext: 'vsf' },
  { mime_type: 'application/vnd.wap.sic' },
  { mime_type: 'application/vnd.wap.slc' },
  { mime_type: 'application/vnd.wap.wbxml', ext: 'wbxml' },
  { mime_type: 'application/vnd.wap.wmlc', ext: 'wmlc' },
  { mime_type: 'application/vnd.wap.wmlscriptc', ext: 'wmlsc' },
  { mime_type: 'application/vnd.webturbo', ext: 'wtb' },
  { mime_type: 'application/vnd.wfa.wsc' },
  { mime_type: 'application/vnd.wmc' },
  { mime_type: 'application/vnd.wmf.bootstrap' },
  { mime_type: 'application/vnd.wordperfect', ext: 'wpd' },
  { mime_type: 'application/vnd.wqd', ext: 'wqd' },
  { mime_type: 'application/vnd.wrq-hp3000-labelled' },
  { mime_type: 'application/vnd.wt.stf', ext: 'stf' },
  { mime_type: 'application/vnd.wv.csp+wbxml' },
  { mime_type: 'application/vnd.wv.csp+xml' },
  { mime_type: 'application/vnd.wv.ssp+xml' },
  { mime_type: 'application/vnd.xara', ext: 'xar' },
  { mime_type: 'application/vnd.xfdl', ext: 'xfdl' },
  { mime_type: 'application/vnd.xfdl.webform' },
  { mime_type: 'application/vnd.xmi+xml' },
  { mime_type: 'application/vnd.xmpie.cpkg' },
  { mime_type: 'application/vnd.xmpie.dpkg' },
  { mime_type: 'application/vnd.xmpie.plan' },
  { mime_type: 'application/vnd.xmpie.ppkg' },
  { mime_type: 'application/vnd.xmpie.xlim' },
  { mime_type: 'application/vnd.yamaha.hv-dic', ext: 'hvd' },
  { mime_type: 'application/vnd.yamaha.hv-script', ext: 'hvs' },
  { mime_type: 'application/vnd.yamaha.hv-voice', ext: 'hvp' },
  { mime_type: 'application/vnd.yamaha.openscoreformat', ext: 'osf' },
  { mime_type: 'application/vnd.yamaha.openscoreformat.osfpvg+xml', ext: 'osfpvg' },
  { mime_type: 'application/vnd.yamaha.smaf-audio', ext: 'saf' },
  { mime_type: 'application/vnd.yamaha.smaf-phrase', ext: 'spf' },
  { mime_type: 'application/vnd.yellowriver-custom-menu', ext: 'cmp' },
  { mime_type: 'application/vnd.zul', ext: 'zir zirz' },
  { mime_type: 'application/vnd.zzazz.deck+xml', ext: 'zaz' },
  { mime_type: 'application/voicexml+xml', ext: 'vxml' },
  { mime_type: 'application/watcherinfo+xml' },
  { mime_type: 'application/whoispp-query' },
  { mime_type: 'application/whoispp-response' },
  { mime_type: 'application/winhlp', ext: 'hlp' },
  { mime_type: 'application/wita' },
  { mime_type: 'application/wordperfect5.1' },
  { mime_type: 'application/wsdl+xml', ext: 'wsdl' },
  { mime_type: 'application/wspolicy+xml', ext: 'wspolicy' },
  { mime_type: 'application/x-abiword', ext: 'abw' },
  { mime_type: 'application/x-ace-compressed', ext: 'ace' },
  { mime_type: 'application/x-authorware-bin', ext: 'aab x32 u32 vox' },
  { mime_type: 'application/x-authorware-map', ext: 'aam' },
  { mime_type: 'application/x-authorware-seg', ext: 'aas' },
  { mime_type: 'application/x-bcpio', ext: 'bcpio' },
  { mime_type: 'application/x-bittorrent', ext: 'torrent' },
  { mime_type: 'application/x-bzip', ext: 'bz' },
  { mime_type: 'application/x-bzip2', ext: 'bz2 boz' },
  { mime_type: 'application/x-cdlink', ext: 'vcd' },
  { mime_type: 'application/x-chat', ext: 'chat' },
  { mime_type: 'application/x-chess-pgn', ext: 'pgn' },
  { mime_type: 'application/x-compress' },
  { mime_type: 'application/x-cpio', ext: 'cpio' },
  { mime_type: 'application/x-csh', ext: 'csh' },
  { mime_type: 'application/x-debian-package', ext: 'deb udeb' },
  { mime_type: 'application/x-director', ext: 'dir dcr dxr cst cct cxt w3d fgd swa' },
  { mime_type: 'application/x-doom', ext: 'wad' },
  { mime_type: 'application/x-dtbncx+xml', ext: 'ncx' },
  { mime_type: 'application/x-dtbook+xml', ext: 'dtb' },
  { mime_type: 'application/x-dtbresource+xml', ext: 'res' },
  { mime_type: 'application/x-dvi', ext: 'dvi' },
  { mime_type: 'application/x-font-bdf', ext: 'bdf' },
  { mime_type: 'application/x-font-dos' },
  { mime_type: 'application/x-font-framemaker' },
  { mime_type: 'application/x-font-ghostscript', ext: 'gsf' },
  { mime_type: 'application/x-font-libgrx' },
  { mime_type: 'application/x-font-linux-psf', ext: 'psf' },
  { mime_type: 'application/x-font-otf', ext: 'otf' },
  { mime_type: 'application/x-font-pcf', ext: 'pcf' },
  { mime_type: 'application/x-font-snf', ext: 'snf' },
  { mime_type: 'application/x-font-speedo' },
  { mime_type: 'application/x-font-sunos-news' },
  { mime_type: 'application/x-font-ttf', ext: 'ttf ttc' },
  { mime_type: 'application/x-font-type1', ext: 'pfa pfb pfm afm' },
  { mime_type: 'application/x-font-vfont' },
  { mime_type: 'application/x-futuresplash', ext: 'spl' },
  { mime_type: 'application/x-gnumeric', ext: 'gnumeric' },
  { mime_type: 'application/x-gtar', ext: 'gtar' },
  { mime_type: 'application/x-gzip' },
  { mime_type: 'application/x-hdf', ext: 'hdf' },
  { mime_type: 'application/x-java-jnlp-file', ext: 'jnlp' },
  { mime_type: 'application/x-latex', ext: 'latex' },
  { mime_type: 'application/x-mobipocket-ebook', ext: 'prc mobi' },
  { mime_type: 'application/x-ms-application', ext: 'application' },
  { mime_type: 'application/x-ms-wmd', ext: 'wmd' },
  { mime_type: 'application/x-ms-wmz', ext: 'wmz' },
  { mime_type: 'application/x-ms-xbap', ext: 'xbap' },
  { mime_type: 'application/x-msaccess', ext: 'mdb' },
  { mime_type: 'application/x-msbinder', ext: 'obd' },
  { mime_type: 'application/x-mscardfile', ext: 'crd' },
  { mime_type: 'application/x-msclip', ext: 'clp' },
  { mime_type: 'application/x-msdownload', ext: 'exe dll com bat msi' },
  { mime_type: 'application/x-msmediaview', ext: 'mvb m13 m14' },
  { mime_type: 'application/x-msmetafile', ext: 'wmf' },
  { mime_type: 'application/x-msmoney', ext: 'mny' },
  { mime_type: 'application/x-mspublisher', ext: 'pub' },
  { mime_type: 'application/x-msschedule', ext: 'scd' },
  { mime_type: 'application/x-msterminal', ext: 'trm' },
  { mime_type: 'application/x-mswrite', ext: 'wri' },
  { mime_type: 'application/x-netcdf', ext: 'nc cdf' },
  { mime_type: 'application/x-pkcs12', ext: 'p12 pfx' },
  { mime_type: 'application/x-pkcs7-certificates', ext: 'p7b spc' },
  { mime_type: 'application/x-pkcs7-certreqresp', ext: 'p7r' },
  { mime_type: 'application/x-rar-compressed', ext: 'rar' },
  { mime_type: 'application/x-sh', ext: 'sh' },
  { mime_type: 'application/x-shar', ext: 'shar' },
  { mime_type: 'application/x-shockwave-flash', ext: 'swf' },
  { mime_type: 'application/x-silverlight-app', ext: 'xap' },
  { mime_type: 'application/x-stuffit', ext: 'sit' },
  { mime_type: 'application/x-stuffitx', ext: 'sitx' },
  { mime_type: 'application/x-sv4cpio', ext: 'sv4cpio' },
  { mime_type: 'application/x-sv4crc', ext: 'sv4crc' },
  { mime_type: 'application/x-tar', ext: 'tar' },
  { mime_type: 'application/x-tcl', ext: 'tcl' },
  { mime_type: 'application/x-tex', ext: 'tex' },
  { mime_type: 'application/x-tex-tfm', ext: 'tfm' },
  { mime_type: 'application/x-texinfo', ext: 'texinfo texi' },
  { mime_type: 'application/x-ustar', ext: 'ustar' },
  { mime_type: 'application/x-wais-source', ext: 'src' },
  { mime_type: 'application/x-x509-ca-cert', ext: 'der crt' },
  { mime_type: 'application/x-xfig', ext: 'fig' },
  { mime_type: 'application/x-xpinstall', ext: 'xpi' },
  { mime_type: 'application/x400-bp' },
  { mime_type: 'application/xcap-att+xml' },
  { mime_type: 'application/xcap-caps+xml' },
  { mime_type: 'application/xcap-el+xml' },
  { mime_type: 'application/xcap-error+xml' },
  { mime_type: 'application/xcap-ns+xml' },
  { mime_type: 'application/xcon-conference-info-diff+xml' },
  { mime_type: 'application/xcon-conference-info+xml' },
  { mime_type: 'application/xenc+xml', ext: 'xenc' },
  { mime_type: 'application/xhtml+xml', ext: 'xhtml xht' },
  { mime_type: 'application/xhtml-voice+xml' },
  { mime_type: 'application/xml', ext: 'xml xsl' },
  { mime_type: 'application/xml-dtd', ext: 'dtd' },
  { mime_type: 'application/xml-external-parsed-entity' },
  { mime_type: 'application/xmpp+xml' },
  { mime_type: 'application/xop+xml', ext: 'xop' },
  { mime_type: 'application/xslt+xml', ext: 'xslt' },
  { mime_type: 'application/xspf+xml', ext: 'xspf' },
  { mime_type: 'application/xv+xml', ext: 'mxml xhvml xvml xvm' },
  { mime_type: 'application/zip', ext: 'zip' },
  { mime_type: 'audio/32kadpcm' },
  { mime_type: 'audio/3gpp' },
  { mime_type: 'audio/3gpp2' },
  { mime_type: 'audio/ac3' },
  { mime_type: 'audio/adpcm', ext: 'adp' },
  { mime_type: 'audio/amr' },
  { mime_type: 'audio/amr-wb' },
  { mime_type: 'audio/amr-wb+' },
  { mime_type: 'audio/asc' },
  { mime_type: 'audio/basic', ext: 'au snd' },
  { mime_type: 'audio/bv16' },
  { mime_type: 'audio/bv32' },
  { mime_type: 'audio/clearmode' },
  { mime_type: 'audio/cn' },
  { mime_type: 'audio/dat12' },
  { mime_type: 'audio/dls' },
  { mime_type: 'audio/dsr-es201108' },
  { mime_type: 'audio/dsr-es202050' },
  { mime_type: 'audio/dsr-es202211' },
  { mime_type: 'audio/dsr-es202212' },
  { mime_type: 'audio/dvi4' },
  { mime_type: 'audio/eac3' },
  { mime_type: 'audio/evrc' },
  { mime_type: 'audio/evrc-qcp' },
  { mime_type: 'audio/evrc0' },
  { mime_type: 'audio/evrc1' },
  { mime_type: 'audio/evrcb' },
  { mime_type: 'audio/evrcb0' },
  { mime_type: 'audio/evrcb1' },
  { mime_type: 'audio/evrcwb' },
  { mime_type: 'audio/evrcwb0' },
  { mime_type: 'audio/evrcwb1' },
  { mime_type: 'audio/example' },
  { mime_type: 'audio/g719' },
  { mime_type: 'audio/g722' },
  { mime_type: 'audio/g7221' },
  { mime_type: 'audio/g723' },
  { mime_type: 'audio/g726-16' },
  { mime_type: 'audio/g726-24' },
  { mime_type: 'audio/g726-32' },
  { mime_type: 'audio/g726-40' },
  { mime_type: 'audio/g728' },
  { mime_type: 'audio/g729' },
  { mime_type: 'audio/g7291' },
  { mime_type: 'audio/g729d' },
  { mime_type: 'audio/g729e' },
  { mime_type: 'audio/gsm' },
  { mime_type: 'audio/gsm-efr' },
  { mime_type: 'audio/ilbc' },
  { mime_type: 'audio/l16' },
  { mime_type: 'audio/l20' },
  { mime_type: 'audio/l24' },
  { mime_type: 'audio/l8' },
  { mime_type: 'audio/lpc' },
  { mime_type: 'audio/midi', ext: 'mid midi kar rmi' },
  { mime_type: 'audio/mobile-xmf' },
  { mime_type: 'audio/mp4', ext: 'mp4a' },
  { mime_type: 'audio/mp4a-latm', ext: 'm4a m4p' },
  { mime_type: 'audio/mpa' },
  { mime_type: 'audio/mpa-robust' },
  { mime_type: 'audio/mpeg', ext: 'mpga mp2 mp2a mp3 m2a m3a' },
  { mime_type: 'audio/mpeg4-generic' },
  { mime_type: 'audio/ogg', ext: 'oga ogg spx' },
  { mime_type: 'audio/parityfec' },
  { mime_type: 'audio/pcma' },
  { mime_type: 'audio/pcma-wb' },
  { mime_type: 'audio/pcmu-wb' },
  { mime_type: 'audio/pcmu' },
  { mime_type: 'audio/prs.sid' },
  { mime_type: 'audio/qcelp' },
  { mime_type: 'audio/red' },
  { mime_type: 'audio/rtp-enc-aescm128' },
  { mime_type: 'audio/rtp-midi' },
  { mime_type: 'audio/rtx' },
  { mime_type: 'audio/smv' },
  { mime_type: 'audio/smv0' },
  { mime_type: 'audio/smv-qcp' },
  { mime_type: 'audio/sp-midi' },
  { mime_type: 'audio/t140c' },
  { mime_type: 'audio/t38' },
  { mime_type: 'audio/telephone-event' },
  { mime_type: 'audio/tone' },
  { mime_type: 'audio/ulpfec' },
  { mime_type: 'audio/vdvi' },
  { mime_type: 'audio/vmr-wb' },
  { mime_type: 'audio/vnd.3gpp.iufp' },
  { mime_type: 'audio/vnd.4sb' },
  { mime_type: 'audio/vnd.audiokoz' },
  { mime_type: 'audio/vnd.celp' },
  { mime_type: 'audio/vnd.cisco.nse' },
  { mime_type: 'audio/vnd.cmles.radio-events' },
  { mime_type: 'audio/vnd.cns.anp1' },
  { mime_type: 'audio/vnd.cns.inf1' },
  { mime_type: 'audio/vnd.digital-winds', ext: 'eol' },
  { mime_type: 'audio/vnd.dlna.adts' },
  { mime_type: 'audio/vnd.dolby.heaac.1' },
  { mime_type: 'audio/vnd.dolby.heaac.2' },
  { mime_type: 'audio/vnd.dolby.mlp' },
  { mime_type: 'audio/vnd.dolby.mps' },
  { mime_type: 'audio/vnd.dolby.pl2' },
  { mime_type: 'audio/vnd.dolby.pl2x' },
  { mime_type: 'audio/vnd.dolby.pl2z' },
  { mime_type: 'audio/vnd.dts', ext: 'dts' },
  { mime_type: 'audio/vnd.dts.hd', ext: 'dtshd' },
  { mime_type: 'audio/vnd.everad.plj' },
  { mime_type: 'audio/vnd.hns.audio' },
  { mime_type: 'audio/vnd.lucent.voice', ext: 'lvp' },
  { mime_type: 'audio/vnd.ms-playready.media.pya', ext: 'pya' },
  { mime_type: 'audio/vnd.nokia.mobile-xmf' },
  { mime_type: 'audio/vnd.nortel.vbk' },
  { mime_type: 'audio/vnd.nuera.ecelp4800', ext: 'ecelp4800' },
  { mime_type: 'audio/vnd.nuera.ecelp7470', ext: 'ecelp7470' },
  { mime_type: 'audio/vnd.nuera.ecelp9600', ext: 'ecelp9600' },
  { mime_type: 'audio/vnd.octel.sbc' },
  { mime_type: 'audio/vnd.qcelp' },
  { mime_type: 'audio/vnd.rhetorex.32kadpcm' },
  { mime_type: 'audio/vnd.sealedmedia.softseal.mpeg' },
  { mime_type: 'audio/vnd.vmx.cvsd' },
  { mime_type: 'audio/vorbis' },
  { mime_type: 'audio/vorbis-config' },
  { mime_type: 'audio/x-aac', ext: 'aac' },
  { mime_type: 'audio/x-aiff', ext: 'aif aiff aifc' },
  { mime_type: 'audio/x-mpegurl', ext: 'm3u' },
  { mime_type: 'audio/x-ms-wax', ext: 'wax' },
  { mime_type: 'audio/x-ms-wma', ext: 'wma' },
  { mime_type: 'audio/x-pn-realaudio', ext: 'ram ra' },
  { mime_type: 'audio/x-pn-realaudio-plugin', ext: 'rmp' },
  { mime_type: 'audio/x-wav', ext: 'wav' },
  { mime_type: 'chemical/x-cdx', ext: 'cdx' },
  { mime_type: 'chemical/x-cif', ext: 'cif' },
  { mime_type: 'chemical/x-cmdf', ext: 'cmdf' },
  { mime_type: 'chemical/x-cml', ext: 'cml' },
  { mime_type: 'chemical/x-csml', ext: 'csml' },
  { mime_type: 'chemical/x-pdb' },
  { mime_type: 'chemical/x-xyz', ext: 'xyz' },
  { mime_type: 'image/bmp', ext: 'bmp' },
  { mime_type: 'image/cgm', ext: 'cgm' },
  { mime_type: 'image/example' },
  { mime_type: 'image/fits' },
  { mime_type: 'image/g3fax', ext: 'g3' },
  { mime_type: 'image/gif', ext: 'gif' },
  { mime_type: 'image/ief', ext: 'ief' },
  { mime_type: 'image/jp2', ext: 'jp2' },
  { mime_type: 'image/jpeg', ext: 'jpeg jpg jpe' },
  { mime_type: 'image/jpm' },
  { mime_type: 'image/jpx' },
  { mime_type: 'image/naplps' },
  { mime_type: 'image/pict', ext: 'pict pic pct' },
  { mime_type: 'image/png', ext: 'png' },
  { mime_type: 'image/prs.btif', ext: 'btif' },
  { mime_type: 'image/prs.pti' },
  { mime_type: 'image/svg+xml', ext: 'svg svgz' },
  { mime_type: 'image/t38' },
  { mime_type: 'image/tiff', ext: 'tiff tif' },
  { mime_type: 'image/tiff-fx' },
  { mime_type: 'image/vnd.adobe.photoshop', ext: 'psd' },
  { mime_type: 'image/vnd.cns.inf2' },
  { mime_type: 'image/vnd.djvu', ext: 'djvu djv' },
  { mime_type: 'image/vnd.dwg', ext: 'dwg' },
  { mime_type: 'image/vnd.dxf', ext: 'dxf' },
  { mime_type: 'image/vnd.fastbidsheet', ext: 'fbs' },
  { mime_type: 'image/vnd.fpx', ext: 'fpx' },
  { mime_type: 'image/vnd.fst', ext: 'fst' },
  { mime_type: 'image/vnd.fujixerox.edmics-mmr', ext: 'mmr' },
  { mime_type: 'image/vnd.fujixerox.edmics-rlc', ext: 'rlc' },
  { mime_type: 'image/vnd.globalgraphics.pgb' },
  { mime_type: 'image/vnd.microsoft.icon' },
  { mime_type: 'image/vnd.mix' },
  { mime_type: 'image/vnd.ms-modi', ext: 'mdi' },
  { mime_type: 'image/vnd.net-fpx', ext: 'npx' },
  { mime_type: 'image/vnd.radiance' },
  { mime_type: 'image/vnd.sealed.png' },
  { mime_type: 'image/vnd.sealedmedia.softseal.gif' },
  { mime_type: 'image/vnd.sealedmedia.softseal.jpg' },
  { mime_type: 'image/vnd.svf' },
  { mime_type: 'image/vnd.wap.wbmp', ext: 'wbmp' },
  { mime_type: 'image/vnd.xiff', ext: 'xif' },
  { mime_type: 'image/x-cmu-raster', ext: 'ras' },
  { mime_type: 'image/x-cmx', ext: 'cmx' },
  { mime_type: 'image/x-freehand', ext: 'fh fhc fh4 fh5 fh7' },
  { mime_type: 'image/x-icon', ext: 'ico' },
  { mime_type: 'image/x-macpaint', ext: 'pntg pnt mac' },
  { mime_type: 'image/x-pcx', ext: 'pcx' },
  // {"mime_type":"image/x-pict", "ext":"pic pct"},
  { mime_type: 'image/x-portable-anymap', ext: 'pnm' },
  { mime_type: 'image/x-portable-bitmap', ext: 'pbm' },
  { mime_type: 'image/x-portable-graymap', ext: 'pgm' },
  { mime_type: 'image/x-portable-pixmap', ext: 'ppm' },
  { mime_type: 'image/x-quicktime', ext: 'qtif qti' },
  { mime_type: 'image/x-rgb', ext: 'rgb' },
  { mime_type: 'image/x-xbitmap', ext: 'xbm' },
  { mime_type: 'image/x-xpixmap', ext: 'xpm' },
  { mime_type: 'image/x-xwindowdump', ext: 'xwd' },
  { mime_type: 'message/cpim' },
  { mime_type: 'message/delivery-status' },
  { mime_type: 'message/disposition-notification' },
  { mime_type: 'message/example' },
  { mime_type: 'message/external-body' },
  { mime_type: 'message/global' },
  { mime_type: 'message/global-delivery-status' },
  { mime_type: 'message/global-disposition-notification' },
  { mime_type: 'message/global-headers' },
  { mime_type: 'message/http' },
  { mime_type: 'message/imdn+xml' },
  { mime_type: 'message/news' },
  { mime_type: 'message/partial' },
  { mime_type: 'message/rfc822', ext: 'eml mime' },
  { mime_type: 'message/s-http' },
  { mime_type: 'message/sip' },
  { mime_type: 'message/sipfrag' },
  { mime_type: 'message/tracking-status' },
  { mime_type: 'message/vnd.si.simp' },
  { mime_type: 'model/example' },
  { mime_type: 'model/iges', ext: 'igs iges' },
  { mime_type: 'model/mesh', ext: 'msh mesh silo' },
  { mime_type: 'model/vnd.dwf', ext: 'dwf' },
  { mime_type: 'model/vnd.flatland.3dml' },
  { mime_type: 'model/vnd.gdl', ext: 'gdl' },
  { mime_type: 'model/vnd.gs-gdl' },
  { mime_type: 'model/vnd.gs.gdl' },
  { mime_type: 'model/vnd.gtw', ext: 'gtw' },
  { mime_type: 'model/vnd.moml+xml' },
  { mime_type: 'model/vnd.mts', ext: 'mts' },
  { mime_type: 'model/vnd.parasolid.transmit.binary' },
  { mime_type: 'model/vnd.parasolid.transmit.text' },
  { mime_type: 'model/vnd.vtu', ext: 'vtu' },
  { mime_type: 'model/vrml', ext: 'wrl vrml' },
  { mime_type: 'multipart/alternative' },
  { mime_type: 'multipart/appledouble' },
  { mime_type: 'multipart/byteranges' },
  { mime_type: 'multipart/digest' },
  { mime_type: 'multipart/encrypted' },
  { mime_type: 'multipart/example' },
  { mime_type: 'multipart/form-data' },
  { mime_type: 'multipart/header-set' },
  { mime_type: 'multipart/mixed' },
  { mime_type: 'multipart/parallel' },
  { mime_type: 'multipart/related' },
  { mime_type: 'multipart/report' },
  { mime_type: 'multipart/signed' },
  { mime_type: 'multipart/voice-message' },
  { mime_type: 'text/calendar', ext: 'ics ifb' },
  { mime_type: 'text/css', ext: 'css' },
  { mime_type: 'text/csv', ext: 'csv' },
  { mime_type: 'text/directory' },
  { mime_type: 'text/dns' },
  { mime_type: 'text/ecmascript' },
  { mime_type: 'text/enriched' },
  { mime_type: 'text/example' },
  { mime_type: 'text/html', ext: 'html htm' },
  { mime_type: 'text/javascript' },
  { mime_type: 'text/parityfec' },
  { mime_type: 'text/plain', ext: 'txt text conf def list log in' },
  { mime_type: 'text/prs.fallenstein.rst' },
  { mime_type: 'text/prs.lines.tag', ext: 'dsc' },
  { mime_type: 'text/red' },
  { mime_type: 'text/rfc822-headers' },
  { mime_type: 'text/richtext', ext: 'rtx' },
  { mime_type: 'text/rtf' },
  { mime_type: 'text/rtp-enc-aescm128' },
  { mime_type: 'text/rtx' },
  { mime_type: 'text/sgml', ext: 'sgml sgm' },
  { mime_type: 'text/t140' },
  { mime_type: 'text/tab-separated-values', ext: 'tsv' },
  { mime_type: 'text/troff', ext: 't tr roff man me ms' },
  { mime_type: 'text/ulpfec' },
  { mime_type: 'text/uri-list', ext: 'uri uris urls' },
  { mime_type: 'text/vnd.abc' },
  { mime_type: 'text/vnd.curl', ext: 'curl' },
  { mime_type: 'text/vnd.curl.dcurl', ext: 'dcurl' },
  { mime_type: 'text/vnd.curl.scurl', ext: 'scurl' },
  { mime_type: 'text/vnd.curl.mcurl', ext: 'mcurl' },
  { mime_type: 'text/vnd.dmclientscript' },
  { mime_type: 'text/vnd.esmertec.theme-descriptor' },
  { mime_type: 'text/vnd.fly', ext: 'fly' },
  { mime_type: 'text/vnd.fmi.flexstor', ext: 'flx' },
  { mime_type: 'text/vnd.graphviz', ext: 'gv' },
  { mime_type: 'text/vnd.in3d.3dml', ext: '3dml' },
  { mime_type: 'text/vnd.in3d.spot', ext: 'spot' },
  { mime_type: 'text/vnd.iptc.newsml' },
  { mime_type: 'text/vnd.iptc.nitf' },
  { mime_type: 'text/vnd.latex-z' },
  { mime_type: 'text/vnd.motorola.reflex' },
  { mime_type: 'text/vnd.ms-mediapackage' },
  { mime_type: 'text/vnd.net2phone.commcenter.command' },
  { mime_type: 'text/vnd.si.uricatalogue' },
  { mime_type: 'text/vnd.sun.j2me.app-descriptor', ext: 'jad' },
  { mime_type: 'text/vnd.trolltech.linguist' },
  { mime_type: 'text/vnd.wap.si' },
  { mime_type: 'text/vnd.wap.sl' },
  { mime_type: 'text/vnd.wap.wml', ext: 'wml' },
  { mime_type: 'text/vnd.wap.wmlscript', ext: 'wmls' },
  { mime_type: 'text/x-asm', ext: 's asm' },
  { mime_type: 'text/x-c', ext: 'c cc cxx cpp h hh dic' },
  { mime_type: 'text/x-fortran', ext: 'f for f77 f90' },
  { mime_type: 'text/x-pascal', ext: 'p pas' },
  { mime_type: 'text/x-java-source', ext: 'java' },
  { mime_type: 'text/x-setext', ext: 'etx' },
  { mime_type: 'text/x-uuencode', ext: 'uu' },
  { mime_type: 'text/x-vcalendar', ext: 'vcs' },
  { mime_type: 'text/x-vcard', ext: 'vcf' },
  { mime_type: 'text/xml' },
  { mime_type: 'text/xml-external-parsed-entity' },
  { mime_type: 'video/3gpp', ext: '3gp' },
  { mime_type: 'video/3gpp-tt' },
  { mime_type: 'video/3gpp2', ext: '3g2' },
  { mime_type: 'video/bmpeg' },
  { mime_type: 'video/bt656' },
  { mime_type: 'video/celb' },
  { mime_type: 'video/dv' },
  { mime_type: 'video/example' },
  { mime_type: 'video/h261', ext: 'h261' },
  { mime_type: 'video/h263', ext: 'h263' },
  { mime_type: 'video/h263-1998' },
  { mime_type: 'video/h263-2000' },
  { mime_type: 'video/h264', ext: 'h264' },
  { mime_type: 'video/jpeg', ext: 'jpgv' },
  { mime_type: 'video/jpeg2000' },
  { mime_type: 'video/jpm', ext: 'jpm jpgm' },
  { mime_type: 'video/mj2', ext: 'mj2 mjp2' },
  { mime_type: 'video/mp1s' },
  { mime_type: 'video/mp2p' },
  { mime_type: 'video/mp2t' },
  { mime_type: 'video/mp4', ext: 'mp4 mp4v mpg4 m4v' },
  { mime_type: 'video/mp4v-es' },
  { mime_type: 'video/mpeg', ext: 'mpeg mpg mpe m1v m2v' },
  { mime_type: 'video/mpeg4-generic' },
  { mime_type: 'video/mpv' },
  { mime_type: 'video/nv' },
  { mime_type: 'video/ogg', ext: 'ogv' },
  { mime_type: 'video/parityfec' },
  { mime_type: 'video/pointer' },
  { mime_type: 'video/quicktime', ext: 'qt mov' },
  { mime_type: 'video/raw' },
  { mime_type: 'video/rtp-enc-aescm128' },
  { mime_type: 'video/rtx' },
  { mime_type: 'video/smpte292m' },
  { mime_type: 'video/ulpfec' },
  { mime_type: 'video/vc1' },
  { mime_type: 'video/vnd.cctv' },
  { mime_type: 'video/vnd.dlna.mpeg-tts' },
  { mime_type: 'video/vnd.fvt', ext: 'fvt' },
  { mime_type: 'video/vnd.hns.video' },
  { mime_type: 'video/vnd.iptvforum.1dparityfec-1010' },
  { mime_type: 'video/vnd.iptvforum.1dparityfec-2005' },
  { mime_type: 'video/vnd.iptvforum.2dparityfec-1010' },
  { mime_type: 'video/vnd.iptvforum.2dparityfec-2005' },
  { mime_type: 'video/vnd.iptvforum.ttsavc' },
  { mime_type: 'video/vnd.iptvforum.ttsmpeg2' },
  { mime_type: 'video/vnd.motorola.video' },
  { mime_type: 'video/vnd.motorola.videop' },
  { mime_type: 'video/vnd.mpegurl', ext: 'mxu m4u' },
  { mime_type: 'video/vnd.ms-playready.media.pyv', ext: 'pyv' },
  { mime_type: 'video/vnd.nokia.interleaved-multimedia' },
  { mime_type: 'video/vnd.nokia.videovoip' },
  { mime_type: 'video/vnd.objectvideo' },
  { mime_type: 'video/vnd.sealed.mpeg1' },
  { mime_type: 'video/vnd.sealed.mpeg4' },
  { mime_type: 'video/vnd.sealed.swf' },
  { mime_type: 'video/vnd.sealedmedia.softseal.mov' },
  { mime_type: 'video/vnd.vivo', ext: 'viv' },
  { mime_type: 'video/x-dv', ext: 'dv dif' },
  { mime_type: 'video/x-f4v', ext: 'f4v' },
  { mime_type: 'video/x-fli', ext: 'fli' },
  { mime_type: 'video/x-flv', ext: 'flv' },
  // {"mime_type":"video/x-m4v", "ext":"m4v"},
  { mime_type: 'video/x-ms-asf', ext: 'asf asx' },
  { mime_type: 'video/x-ms-wm', ext: 'wm' },
  { mime_type: 'video/x-ms-wmv', ext: 'wmv' },
  { mime_type: 'video/x-ms-wmx', ext: 'wmx' },
  { mime_type: 'video/x-ms-wvx', ext: 'wvx' },
  { mime_type: 'video/x-msvideo', ext: 'avi' },
  { mime_type: 'video/x-sgi-movie', ext: 'movie' },
  { mime_type: 'x-conference/x-cooltalk', ext: 'ice' },
];
